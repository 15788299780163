import { TextLink } from '@teamsnap/snap-ui';
import { Button, Field } from '@teamsnap/teamsnap-ui';
import React from 'react';
import * as Sentry from '@sentry/browser';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import { PaymentExpirationModal } from 'components/PaymentExpirationModal/PaymentExpirationModal';
import { PayWithCashModal } from 'components/PayWithCashModal/PayWithCashModal';
import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';
import { InstallmentPreview } from 'components/shared/InstallmentPreview/InstallmentPreview';
import { InstallmentOption, Order } from 'core/api';
import { REGISTRATION_URL } from 'core/constants';
import ENVIRONMENT from 'core/environment';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import { FeatureFlagService, getFormattedAmount } from 'frontend-toolkit';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { createInstallments, updateSelectedPlan } from 'state/installments/installmentsSlice';
import {
  updateOrder,
  useFormIdSelector,
  useFormStateSelector,
  useOrderStateSelector,
  useOrderWaitlistStatus,
  useOrgIdSelector,
} from 'state/order/orderSlice';
import { createOfflinePayment, useOfflinePaymentResultSelector } from 'state/payment/paymentSlice';
import { useUserSelector } from 'state/user/userSlice';
import { CountDownExpirationBanner } from '../CountDownExpirationBanner';
import './Installments.scss';
import { getDepositDescription, getInstallmentsDescription } from './utils';
import { UpdateOrder } from 'core/api';

const PAY_NOW = -1;
const PAY_NOW_ID = 'payNow';
const PAY_OFFLINE = -2;

export const PayNowOption = ({
  setSelected,
  selected,
  amount,
}: {
  amount: number;
  selected?: number;
  setSelected: (id: number) => void;
}) => {
  const onSelect = () => {
    setSelected(PAY_NOW);
  };
  const isSelected = selected === PAY_NOW;

  return (
    <div className={`Installments__installment-option ${isSelected && 'Installments__installment-option-selected'}`}>
      <>
        <Field
          type="radio"
          isInline
          id={PAY_NOW_ID}
          formFieldProps={{
            size: 'large',
            text: 'Pay in full',
            onClick: onSelect,
            checked: isSelected,
          }}
          name={PAY_NOW_ID}
        />
        {isSelected && (
          <div className="sui-mt-1">
            {getFormattedAmount(amount / 100)}
            <small>Online fees will be calculated on the next step.</small>
          </div>
        )}
      </>
    </div>
  );
};

export const PayCashOption = ({ setSelected, selected }: { selected?: number; setSelected: (id: number) => void }) => {
  const onSelect = () => {
    setSelected(PAY_OFFLINE);
  };
  const isSelected = selected === PAY_OFFLINE;

  return (
    <div className={`Installments__installment-option ${isSelected && 'Installments__installment-option-selected'}`}>
      <>
        <Field
          type="radio"
          isInline
          id="payCash"
          formFieldProps={{
            size: 'large',
            text: 'Pay cash',
            onClick: onSelect,
            checked: isSelected,
          }}
          name="payCash"
        />
        {isSelected && (
          <div className="sui-mt-1">
            <small>Please submit your cash payment directly to your organization.</small>
          </div>
        )}
      </>
    </div>
  );
};

export const InstallmentPlanOption = ({
  installmentOption,
  setSelected,
  selected,
}: {
  installmentOption: InstallmentOption;
  setSelected: (id: number) => void;
  selected?: number;
}) => {
  const { installmentPlan, installments } = installmentOption;
  const onSelect = () => {
    setSelected(installmentPlan.id);
  };
  const isSelected = installmentPlan.id === selected;

  return (
    <div className={`Installments__installment-option ${isSelected && 'Installments__installment-option-selected'}`}>
      <Field
        type="radio"
        isInline
        id={`paymentOption${installmentPlan.id}`}
        formFieldProps={{
          size: 'large',
          text: `${getDepositDescription(installmentPlan)} ${getInstallmentsDescription(installmentPlan)}`,
          onClick: onSelect,
          checked: isSelected,
        }}
        name={`paymentOption${installmentPlan.id}`}
      />
      {isSelected && (
        <div className="sui-mt-1">
          <div>
            <small>Online fees will be calculated on the next step.</small>
          </div>
          <InstallmentPreview {...{ installments, installmentPlan }} />
        </div>
      )}
    </div>
  );
};

interface Props {
  installmentOptions?: InstallmentOption[];
  order?: Order;
}

export const Installments = ({ installmentOptions, order }: Props) => {
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number>();
  const [hasCountDownEnded, setHasCountDownEnded] = React.useState(false);
  const [displayCashModal, setDisplayCashModal] = React.useState(false);

  const { amount, id: orderId } = order || {};
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const formId = useFormIdSelector();
  const user = useUserSelector();
  const orderData = useOrderStateSelector();
  const formData = useFormStateSelector();
  const organizationId = useOrgIdSelector();
  const isWaitlistedOrder = useOrderWaitlistStatus();
  const offlinePaymentResult = useOfflinePaymentResultSelector();

  React.useEffect(() => {
    if (offlinePaymentResult) {
      if (offlinePaymentResult.status === 'waiting_for_payment') {
        navigate(`/order/${orderId}/confirmation`);
      }
    }
  }, [offlinePaymentResult, orderId, navigate]);

  const onSubmit = () => {
    setIsProcessing(true);
    if (!orderId || selected === undefined) return;
    dispatch(updateSelectedPlan(selected));
    const installmentPlanId = selected > 0 ? selected : undefined;

    if (orderData?.allowOfflineCheckout && selected === PAY_OFFLINE) {

      // ensure we clear out any partner offering line items before we continue as these will not be applicable to offline checkout currently. 
      const lineItemsToKeep = orderData?.orderLineItems.filter(lineItem => lineItem.type !== 'partner_offering') || [];
      const updatedOrderData = { orderLineItems: lineItemsToKeep } as UpdateOrder;

      dispatch(updateOrder({orderId, orderData: updatedOrderData})).then(() =>
        dispatch(createOfflinePayment({ orderId: +orderId }))
      );
    } else {
      dispatch(createInstallments({ orderId, installmentPlanId })).then(() => {
        if (formData?.availableAddons && formData.availableAddons.length > 0) {
          navigate(`/order/${orderId}/addons`);
        } else {
          navigate(`/order/${orderId}/payment`);
        }
      });
    }
  };

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId || undefined
  );

  return (
    <div className="Installments">
      <ActionContainer
        submitting={false}
        removeContentFormatting={true}
        header={
          <Header
            title="Checkout - Installments"
            //   rightIcon={<CartButton />}
            navigation={
              formId && (
                <Button
                  iconPosition="left"
                  mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                  icon="arrow-left"
                  type="link"
                  size="large"
                  onClick={() => {
                    window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
                  }}
                />
              )
            }
            profileName={`${user?.firstName} ${user?.lastName}`}
          />
        }
        extraFooter={<div />}
        footer={
          <div className="t:sui-flex t:items-center t:sui-justify-between">
            {formId ? (
              <Button
                key="add-another-member"
                mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
                icon="arrow-left"
                iconPosition="left"
                onClick={() => {
                  window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
                }}
              >
                Back
              </Button>
            ) : (
              <span />
            )}
            <Button
              key="check-out"
              color="primary"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              icon={isProcessing ? 'loader' : 'arrow-right'}
              iconPosition="right"
              onClick={onSubmit}
              isDisabled={isProcessing || !selected}
              testId="next-button"
            >
              {selected === PAY_OFFLINE ? 'Submit' : 'Next'}
            </Button>
          </div>
        }
        children={
          <div className="sui-pl-1 sui-pr-1">
            {capacityLimitsAreEnabled && orderData?.checkoutStartTime && !isWaitlistedOrder && (
              <CountDownExpirationBanner
                serverTime={orderData.currentMillis}
                checkoutStartDate={new Date(orderData.checkoutStartTime).toUTCString()}
                isCountDownEnded={hasCountDownEnded}
                onFinishCountDown={() => setHasCountDownEnded(true)}
              />
            )}

            {isWaitlistedOrder && <OrderSummary />}

            {amount && <PayNowOption {...{ setSelected, selected, amount }} />}

            {installmentOptions?.map((option) => {
              return (
                <InstallmentPlanOption
                  key={option.installmentPlan?.id || PAY_NOW_ID}
                  installmentOption={option}
                  {...{ setSelected, selected }}
                />
              );
            })}

            {selected === PAY_OFFLINE ? <PayCashOption {...{ setSelected, selected }} /> : null}

            {order?.allowOfflineCheckout && selected !== PAY_OFFLINE ? (
              <TextLink
                onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
                  e.preventDefault();
                  setDisplayCashModal(true);
                }}
                href=""
                variantType="secondary"
                data-testid="pay-offline-radio"
                className="sui-text-neutral-text sui-no-underline"
              >
                More payment options
              </TextLink>
            ) : null}
          </div>
        }
      />

      {capacityLimitsAreEnabled && (
        <PaymentExpirationModal
          isOpen={hasCountDownEnded}
          onClose={() => {
            window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
          }}
        />
      )}

      {displayCashModal ? (
        <PayWithCashModal
          show={displayCashModal}
          onClose={(payWithCash) => {
            setDisplayCashModal(false);
            if (payWithCash) {
              setSelected(PAY_OFFLINE);
            } else {
              if (selected) {
                setSelected(selected);
              } else {
                setSelected(undefined);
              }
            }
          }}
        />
      ) : null}
    </div>
  );
};
