import { Icon } from '@teamsnap/teamsnap-ui';
import { capitalize } from 'components/Installments/utils';
import { OrderLineItems } from 'components/OrderLineItems/OrderLineItems';
import { FormResultInterface, HouseholdPerson, InstallmentResult, OrderLineItem } from 'core/api';
import { DateFormat, DateService, getFormattedAmount } from 'frontend-toolkit';

export const SuccessHeading = ({ text, body }: { text: string; body?: string | JSX.Element }) => (
  <div className="sui-mb-2 ">
    <h2 className="sui-heading-md sui-text-green-7 sui-font-semibold" data-testid="confirmation-heading">
      <Icon
        name="task-alt"
        style={{
          top: 0,
          color: '#199a50',
          marginTop: '4px',
          marginRight: '5px',
        }}
      />
      {text}
    </h2>
    {body && (
      <div className="sui-body">
        <p className="sui-my-2 sui-text-gray-8">{body}</p>
      </div>
    )}
  </div>
);

export const OrderSummary = ({
  orderNumber,
  date,
  paymentMethodText,
}: {
  orderNumber?: string;
  date?: string;
  paymentMethodText?: string;
}) => (
  <>
    <h3 className="sui-text-gray-9 sui-pt-2 sui-font-normal" data-testid="confirmation-summary-heading">
      Order Summary
    </h3>
    <div className="sui-my-1 sui-pt-2 sui-pb-2 sui-border-t sui-border-b sui-border-x-0 sui-border-t-gray-5 sui-border-b-gray-5 sui-border-solid">
      <div className="sui-flex sui-text-gray-8">
        <div className="sui-flex-1">Order Number</div>
        <div className="sui-flex-1 sui-text-right">
          <span style={{ whiteSpace: 'nowrap' }}>{orderNumber}</span>
        </div>
      </div>
      {date && (
        <div className="sui-flex sui-text-gray-8">
          <div className="sui-flex-1">Date</div>
          <div className="sui-flex-1 sui-text-right">{date}</div>
        </div>
      )}
      {paymentMethodText && (
        <div className="sui-flex sui-text-gray-8">
          <div className="sui-flex-1" data-testid="OrderSummary--payment-method-text">
            Payment Method
          </div>
          <div data-testid="orderConfirmationPaymentMethod" className="sui-flex-1 sui-text-right">
            {paymentMethodText}
          </div>
        </div>
      )}
    </div>
  </>
);

export const OrderDetails = ({
  orderLineItems,
  householdPeople,
  formResults,
}: {
  orderLineItems?: OrderLineItem[];
  householdPeople?: HouseholdPerson[];
  formResults?: FormResultInterface[];
}) => (
  <>
    <h3 className="sui-text-gray-9 sui-pt-2 sui-font-normal" data-testid="confirmation-details-heading">Order Details</h3>
    <div className="sui-my-1 sui-border-t sui-border-b-0 sui-border-x-0 sui-border-t-gray-5 sui-border-solid">
      {orderLineItems && <OrderLineItems lineItems={orderLineItems} {...{ householdPeople, formResults }} />}
    </div>
  </>
);

export const InstallmentSummary = ({
  todaysPayment,
  installmentsLeft,
}: {
  todaysPayment?: string;
  installmentsLeft?: number;
}) => (
  <>
    <div className="sui-my-1 sui-pt-2 sui-pb-1 sui-border-t sui-border-b-0 sui-border-x-0 sui-border-t-gray-5  sui-border-solid">
      <div className="sui-flex sui-text-gray-8">
        <div className="sui-flex-1">Today's Payment</div>
        <div className="sui-flex-1 sui-text-right">{todaysPayment}</div>
      </div>
      <div className="sui-flex sui-text-gray-8">
        <div className="sui-flex-1" data-testid="confirmation-installments-label">
          Installments left
        </div>
        <div className="sui-flex-1 sui-text-right" data-testid="confirmation-installments-value">
          {installmentsLeft}
        </div>
      </div>
    </div>
  </>
);

export const PaymentSummary = ({
  subtotal,
  showFee,
  isInstallmentPayment,
  fee,
  total,
}: {
  subtotal?: string;
  showFee?: boolean;
  isInstallmentPayment?: boolean;
  fee?: string;
  total?: string;
}) => {
  return (
    <>
      <div className="sui-my-1 sui-pt-2 sui-pb-2 sui-border-t sui-border-b sui-border-x-0 sui-border-t-gray-5 sui-border-b-gray-5 sui-border-solid">
        <div className="sui-flex sui-text-gray-8">
          <div className="sui-flex-1" data-testid="order-subtotal-text">Subtotal</div>
          <div className="sui-flex-1 sui-text-right" data-testid="order-subtotal-amount">{subtotal}</div>
        </div>
        {showFee && (
          <div className="sui-flex sui-text-gray-8" data-testId="processing-fee-row">
            <div className="sui-flex-1">Online fee</div>
            <div className="sui-flex-1 sui-text-right">{fee}</div>
          </div>
        )}
      </div>
      <div className="sui-my-1 sui-pt-2 sui-pb-2 sui-font-semibold ">
        <div className="sui-flex sui-text-gray-9">
          <div className="sui-flex-1" data-testid="confirmation-total-label">
            {isInstallmentPayment ? 'Paid Now' : 'Total'}
          </div>
          <div className="sui-flex-1 sui-text-right" data-testid="confirmation-total-amount">
            {total}
          </div>
        </div>
      </div>
    </>
  );
};

export const OrderTotalSummary = ({
  totalProcessingFeesText,
  totalDiscountsText,
  subtotalText,
  totalText,
}: {
  totalProcessingFeesText?: string;
  totalDiscountsText?: string;
  subtotalText?: string;
  totalText: string;
}) => (
  <div className="sui-my-1 sui-pt-2 sui-pb-2 sui-border-t sui-border-b sui-border-x-0 sui-border-t-gray-5 sui-border-b-gray-5 sui-border-solid">
    <div className="sui-flex sui-text-gray-8">
      <div className="sui-flex-1" data-testid="order-subtotal-text">Organization Subtotal</div>
      <div className="sui-flex-1 sui-text-right" data-testid="order-subtotal-amount">{subtotalText}</div>
    </div>
    {totalDiscountsText && (
      <div className="sui-flex sui-text-gray-8" data-testid="order-discount-text">
        <div className="sui-flex-1">Discount (Total)</div>
        <div className="sui-flex-1 sui-text-right sui-text-red" data-testid="order-discount-amount">{totalDiscountsText}</div>
      </div>
    )}
    {totalProcessingFeesText && (
      <div className="sui-flex sui-text-gray-8" data-testid="processing-fee-row">
        <div className="sui-flex-1">Online Fees (Total)</div>
        <div className="sui-flex-1 sui-text-right" data-testid="order-processing-fees-amount">{totalProcessingFeesText}</div>
      </div>
    )}
    {totalText && (
      <div className="sui-my-1 sui-pt-2 sui-pb-0.5 sui-font-semibold ">
        <div className="sui-flex sui-text-gray-9">
          <div className="sui-flex-1" data-testid="order-total-label">
            Organization Total
          </div>
          <div className="sui-flex-1 sui-text-right" data-testid="order-total-amount">
            {totalText}
          </div>
        </div>
      </div>
    )}
  </div>
);

export const BalanceSummary = ({ balanceText }: { balanceText?: string }) => (
  <>
    <div className="sui-my-1 sui-pt-2 sui-pb-0.5 sui-font-semibold ">
      <div className="sui-flex sui-text-gray-9">
        <div className="sui-flex-1" data-testid="OrderTotalSummary--balance-text">
          Balance
        </div>
        <div className="sui-flex-1 sui-text-right" data-testid="OrderTotalSummary--balance-amount">
          {balanceText}
        </div>
      </div>
    </div>
  </>
);

export const InstallmentLineItem = ({ installment }: { installment: InstallmentResult }) => {
  const displayDate = installment.paidAt || installment.scheduledDate;
  const displayStatus = installment.status === 'paid' ? 'Paid' : `(${capitalize(installment.status)})`;
  const displayPaymentMethod = () => {
    if (installment.status === 'scheduled') return null;

    if (typeof installment.paymentMethod?.accountName === 'string') {
      return (
        <div className={`sui-flex-1 sui-text-gray-${installment.status === 'paid' ? '9' : '7'}`}>
          {installment.paymentMethod.accountName.toUpperCase()}{' '}
          {installment.paymentMethod.accountName !== 'offline'
            ? `(****${installment.paymentMethod.last4})`
            : `(${
                installment.paymentMethod.accountType.slice(0, 1).toUpperCase() +
                installment.paymentMethod.accountType.slice(1).toLowerCase()
              })`}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className={`sui-flex sui-text-gray-${installment.status === 'paid' ? '9' : '7'} installmentLineItem-${
          installment.status
        }  sui-pt-1`}
      >
        <div className="sui-flex-1">
          {displayDate && DateService.dateToString(new Date(displayDate), DateFormat.SHORT_DATE)} {displayStatus}
        </div>
        <div className="sui-flex-1 sui-text-right">
          {getFormattedAmount((installment.amount + (installment.feeAmount || 0)) / 100)}
        </div>
      </div>

      {displayPaymentMethod()}
    </>
  );
};

export const InstallmentsRollup = ({
  installments,
  onClickHandler,
}: {
  installments: InstallmentResult[];
  onClickHandler: () => void;
}) => {
  const lastPaymentScheduledDate = installments?.slice(-1)[0]?.scheduledDate;
  const lastPaymentScheduledDateText = lastPaymentScheduledDate
    ? DateService.dateToString(new Date(lastPaymentScheduledDate), DateFormat.SHORT_DATE)
    : '';
  const total = installments?.reduce((acc, i) => {
    acc = acc + i.amount + (i.feeAmount || 0);
    return acc;
  }, 0);

  return (
    <div className="sui-flex sui-text-gray-7 sui-pt-1 sui-pb-1">
      <div className="sui-flex-1">
        <span
          onClick={onClickHandler}
          onKeyUp={onClickHandler}
          role="button"
          tabIndex={0}
          style={{ whiteSpace: 'nowrap' }}
        >
          + {installments.length} more scheduled payments, <br />
          last payment scheduled <br />
        </span>
        {lastPaymentScheduledDateText}
      </div>
      <div className="sui-flex-1 sui-text-right">{getFormattedAmount(total / 100)}</div>
    </div>
  );
};

export const ConfirmationMessage = ({ html }: { html: string }) => (
  <div>
    <h2 className="sui-heading-md sui-text-center sui-my-2">What's Next</h2>
    <div className="confirmation-content" dangerouslySetInnerHTML={{ __html: html }}></div>
  </div>
);

export const AddonSummary = ({includedAddons}:{includedAddons: string[]}) => (
  <div className="sui-mt-2" data-testid="addon-summary">
    <h3 className="sui-text-gray-9 sui-py-2 sui-font-normal">
      Add-ons:
    </h3>
    <ul className="sui-mx-4 sui-body">
      {includedAddons.map(addon => (
        <li>{addon}</li>
      ))}
    </ul>
    <p className="sui-body sui-mt-2">
      Manage coverage at <a href="https://vi-coverage.com/" target="_blank" rel="noreferrer">vi-coverage.com</a>
    </p>
    <p className="sui-body sui-mt-2">
      <b>Please note</b><br />
      Registration Cancellation Protection and Gap Medical Insurance add-ons will appear on your bank statement as "VI-COVERAGE.COM" or "VI-COVER". You will receive a separate confirmation email from Vertical Insure.
    </p>
  </div>
)
