import { Button, Modal } from '@teamsnap/teamsnap-ui';
import React from 'react';
import { getDepositDescription, getInstallmentsDescription } from 'components/Installments/utils';
import { InstallmentPreview } from 'components/shared/InstallmentPreview/InstallmentPreview';
import { useInstallmentStateSelector, useSelectedInstallmentPlanSelector } from 'state/installments/installmentsSlice';

import './InstallmentTermsModal.scss';

interface Props {
  show: boolean;
  closeFn: () => void;
}
export const InstallmentTermsModal = ({ show, closeFn }: Props) => {
  const installmentPlan = useSelectedInstallmentPlanSelector();
  const installments = useInstallmentStateSelector();
  const showFeeMsg = installments?.some((item) => item.feeAmount);
  return (
    <Modal
      {...{ show, closeFn }}
      heading="Installment Plan Terms"
      style={{ width: '100%', borderRadius: '0', maxWidth: '30rem' }}
    >
      <div className="InstallmentTermsModal--preview-wrapper sui-p-1">
        {installmentPlan && `${getDepositDescription(installmentPlan)} ${getInstallmentsDescription(installmentPlan)}`}
        {installments && <InstallmentPreview {...{ installmentPlan, installments }} showFee />}
      </div>
      <div className="sui-py-2 sui-px-1">
        Payments are processed automatically with your submitted payment method.{' '}
        {showFeeMsg && 'An online fee is included in each payment.'}
      </div>
      <div className="sui-flex sui-justify-end sui-px-2">
        <Button
          key="check-out"
          color="primary"
          mods="sui-px-3 sui-py-1 sui-h-auto"
          iconPosition="right"
          onClick={closeFn}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
